import logo from './logo.svg';
import LandingPage from './Components/LandingPage';
import Header from './Components/Header';
import HeroSection from './Components/HeroSection';
import FeaturesSection from './Components/FeaturesSection';
import SocialProof from './Components/SocialProof';
import CallToAction from './Components/CTA';
import FormComponent from './Components/Form';
import TrustBadge from './Components/TrustBadge';
import SimulatePurchaseNotification from "./Components/Scripts";
import LimitedTimeOfferBanner from "./Components/LimitedTimeOffer";
import Carousel from "./Components/Carousel";
import BonusComponent from "./Components/Bonus";
// import './App.css';

function App() {
  const images = [
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    // Add more image URLs as needed
  ];
  return (
    <>
      <Header />
      <HeroSection />
      <FormComponent />
      <FeaturesSection />
      <Carousel images={images} />
      <SocialProof />
      <BonusComponent />
      <CallToAction />
      <LimitedTimeOfferBanner expiryDate="2024-04-07T23:59:59" />
      <SimulatePurchaseNotification />
      {/* <TrustBadge /> */}
    </>
  );
}

export default App;
