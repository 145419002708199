import React from 'react';

const Layout = ({ children }) => {
  return (
    <div>
      <header>
        {/* Header components */}
        <h1>Trading Indicator Registration</h1>
      </header>
      <main>
        {children}
      </main>
      <footer>
        {/* Footer components */}
        <p>© 2024 Trading Indicator Registration</p>
      </footer>
    </div>
  );
};

export default Layout;
