import React from 'react';
import './Bonus.css'; // Import the CSS file
import telegram from "./telegram.png"
import whatsapp from "./whatsapp.png"
import tools from "./tools.png"

const BonusComponent = () => {
  const Bonuses = [{ img: telegram, text: "YOU WILL GET FREE ACCESS TO PREMIUM TELEGRAM GROUP", price: "500" }, { img: whatsapp, text: "YOU WILL GET FREE ACCESS TO PREMIUM WHATSAPP GROUP ", price: "500" }, { img: tools, text: "YOU WILL GET FREE PREMIUM TRADING TOOL'S", price: "1000" }]

  return (
    <div className="bonus-container">
      {Bonuses.map((bonus, index) => (
        <div className="bonus-card" key={index}>
          <div className="bonus-header">
            <h3> 🎁 Bonus {index + 1}</h3>
          </div>
          <div className="bonus-image">
            <img src={bonus.img} alt="Bonus" />
          </div>
          <div className="bonus-text">
            <p>{bonus.text}</p>
          </div>
          <div className="bonus-footer">
            <p> Worth ${bonus.price}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BonusComponent;
