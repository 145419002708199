import React from 'react';
import './HeroSection.css'; // Import the CSS file

const HeroSection = () => {
  const scrollToForm = () => {
    const formSection = document.getElementById('Form');
    formSection.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Unlock Your First or Next 'PROFIT-BOOSTING INDICATOR' to Secure Winning Trades Daily... Effortlessly Automated"</h1>
        <p>"Join 9128+ Traders in the Next 3 Hours and Harness Free Tools to Secure Profitable Trades Daily
Discover How Even First-Time Traders Can Succeed Using Free Tools"</p>
        <button onClick={scrollToForm} >YES I WANT CONSISTENT PROFITS</button>
      </div>
    </section>
  );
};

export default HeroSection;
