import React from 'react';
import './CallToAction.css'; // Import the CSS file

const CallToAction = () => {
  const scrollToForm = () => {
    const formSection = document.getElementById('Form');
    formSection.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <section className="cta">
      <h2>Ready to Elevate Your Trading?</h2>
      <p>Sign up for a free trial </p>
      <button className="cta-button" onClick={scrollToForm} >Yes I Wanted To Recover My Losses</button>
      <p class='be-quick'>*** Be Quick! Last Few Spots Left For Free Trial</p>
    </section>
  );
};

export default CallToAction;
