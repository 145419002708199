import React, { useState, useEffect } from 'react';
import './Carousel.css'; // Import the CSS styles
import image2 from "./image copy.png"
import image3 from "./image copy 2.png"
import image4 from "./image copy 3.png"
import image5 from "./image copy 4.png"

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [ 
    image2,
    image3,
    image4,
    image5
  ]
  useEffect(() => {
    const interval = setInterval(() => {
      const nextSlide = (currentSlide + 1) % images.length;
      setCurrentSlide(nextSlide);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [currentSlide, images.length]);

  const goToPrevSlide = () => {
    const prevSlide = (currentSlide - 1 + images.length) % images.length;
    setCurrentSlide(prevSlide);
  };

  const goToNextSlide = () => {
    const nextSlide = (currentSlide + 1) % images.length;
    setCurrentSlide(nextSlide);
  };

  return (
    <div className="carousel">
      <div className="slide-container">
        {images.map((image, index) => (
          <div
            key={index}
            className={index === currentSlide ? 'slide active' : 'slide'}
          >
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
        <button className="prev" onClick={goToPrevSlide}>
          &#10094; {/* Left arrow */}
        </button>
        <button className="next" onClick={goToNextSlide}>
          &#10095; {/* Right arrow */}
        </button>
      </div>
    </div>
  );
};

export default Carousel;
