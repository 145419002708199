// SimulatedPurchaseNotifications.js
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import "./Notifications.css"

const indianCities = [
    "Mumbai", "Delhi", "Bangalore", "Hyderabad", "Ahmedabad",
    "Chennai", "Kolkata", "Pune", "Jaipur", "Surat",
    "Lucknow", "Kanpur", "Nagpur", "Indore", "Thane",
    "Bhopal", "Visakhapatnam", "Pimpri-Chinchwad", "Patna", "Vadodara",
    "Ghaziabad", "Ludhiana", "Agra", "Nashik", "Faridabad",
    "Meerut", "Rajkot", "Kalyan-Dombivali", "Vasai-Virar", "Varanasi",
    "Srinagar", "Aurangabad", "Dhanbad", "Amritsar", "Navi Mumbai",
    "Allahabad", "Ranchi", "Haora", "Coimbatore", "Jabalpur",
    "Gwalior", "Vijayawada", "Jodhpur", "Madurai", "Raipur",
    "Kota", "Guwahati", "Chandigarh", "Solapur", "Hubli-Dharwad",
    "Bareilly", "Moradabad", "Mysore", "Gurgaon", "Aligarh",
    "Jalandhar", "Tiruchirappalli", "Bhubaneswar", "Salem", "Warangal",
    "Guntur", "Bhiwandi", "Saharanpur", "Gorakhpur", "Bikaner",
    "Amravati", "Noida", "Jamshedpur", "Bhilai", "Cuttack",
    "Firozabad", "Kochi", "Nellore", "Bhavnagar", "Dehradun",
    "Durgapur", "Asansol", "Rourkela", "Nanded", "Kolhapur",
    "Ajmer", "Akola", "Gulbarga", "Jamnagar", "Ujjain",
    "Loni", "Siliguri", "Jhansi", "Ulhasnagar", "Jammu",
    "Sangli-Miraj & Kupwad", "Mangalore", "Erode", "Belgaum", "Ambattur",
    // Add more cities here
];

const indianNames = [
    "Sweta Subramanian", "Pradeep Kale", "Nupoor Gupta", "Aruna Ahamad", "Ramesh Rao",
    "Suresh", "Rahul", "Shyam", "Vijay", "Gopal",
    "Anjali", "Sneha", "Pooja", "Neeta", "Ritu",
    "Rajeshwari", "Kavita", "Mohan", "Kiran", "Anita",
    "Divya", "Alok", "Manisha", "Amit", "Sunita",
    "Rahul", "Smita", "Manoj", "Radha", "Kishore",
    "Sarita", "Sanjay", "Anju", "Sunil", "Pallavi",
    "Raj", "Meera", "Vivek", "Geeta", "Rohit",
    "Neha", "Avinash", "Mita", "Anil", "Asha",
    "Dinesh", "Preeti", "Arun", "Sangita", "Ravi",
    "Komal", "Deepak", "Shobha", "Vinay", "Renu",
    "Amita", "Harish", "Monika", "Satish", "Sangeeta",
    "Sushil", "Reena", "Rajesh", "Swati", "Amar",
    "Kirti", "Rajendra", "Jyoti", "Prakash", "Priya",
    "Vikas", "Sunita", "Ashok", "Indira", "Rajiv",
    "Neeraj", "Anjana", "Sunil", "Seema", "Aruna",
    "Manoj", "Usha", "Rajeev", "Shilpa", "Mahesh",
    "Savita", "Ravi", "Sarika", "Anil", "Poonam",
    "Vijay", "Shweta", "Amitabh", "Sangeeta", "Nitin",
    // Add more names here
];


function getRandomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
}

function getRandomTimestamp() {
    const now = new Date();
    const randomHours = Math.floor(Math.random() * 12) + 1; // 1 to 12 hours
    const randomMinutes = Math.floor(Math.random() * 60);
    const amOrPm = now.getHours() >= 12 ? 'PM' : 'AM';
    const hours12Format = now.getHours() % 12 || 12;
    return `${hours12Format}:${String(randomMinutes).padStart(2, '0')} ${amOrPm}`;
}

const SimulatedPurchaseNotifications = () => {
    useEffect(() => {
        const simulatePurchaseNotification = () => {
            const randomName = getRandomElement(indianNames);
            const randomCity = getRandomElement(indianCities);
            const timestamp = getRandomTimestamp();
            toast.success(` ${randomName} from ${randomCity} Registered for Free Trial At  ${timestamp}`, {
                position: "bottom-left", // Show notification on bottom left side
                autoClose: 3000,// Close the notification after 5 seconds
                pauseOnHover: false,
                toastClassName: "notification", // Custom class for notification styling
            });
            // console.log(`New purchase by ${randomName} in ${randomCity} at ${timestamp}`);
        };

        const intervalId = setInterval(simulatePurchaseNotification, 9000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <ToastContainer />
    );
}

export default SimulatedPurchaseNotifications;
