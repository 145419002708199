import React from 'react';
import './FeaturesSection.css'; // Import the CSS file
import buySellSignalsIcon from "./buy-sell-signals-icon.png";
import customSettingsIcon from "./customizable-settings-icon.png";
import realTimeIcons from "./real-time-analysis-icon.png";
import userFriendlyIcons from "./user-friendly-interface-icon.png";

const FeaturesSection = () => {
  return (
    <section className="features">
      <h2>HERE IS WHAT YOU ARE GOING TO GET</h2>
      <div className="feature">
        <img src={buySellSignalsIcon} alt="Buy and Sell Signals" />
        <h3>Unlock the "1-STEP METHOD" for High-Profit Trading.</h3>
        <p>Discover a streamlined approach to maximizing your trading profits with our innovative trading indicator</p>
      </div>
      <div className="feature">
        <img src={customSettingsIcon} alt="Customizable Settings" />
        <h3>Tap into "ADVANCED ANALYTICS" for Optimal Results</h3>
        <p>Leverage powerful analytics tools integrated into our trading indicator to measure performance and fine-tune your trading strategy for maximum profitability.</p>
      </div>
      <div className="feature">
        <img src={realTimeIcons} alt="Real-time Analysis" />
        <h3>Stay Ahead with "REAL-TIME ANALYSIS"</h3>
        <p>Empower your trades with real-time insights, ensuring "QUICK DECISION" in FAST-MOVING MARKETS.</p>
      </div>
      <div className="feature">
        <img src={userFriendlyIcons} alt="User-friendly Interface" />
        <h3>Seamless User Experience</h3>
        <p>Enjoy a sleek, user-friendly interface designed to simplify "TRADING FOR BEGINNERS AND EXPERTS" alike.</p>
      </div>
    </section>
  );
};

export default FeaturesSection;
