import React, { useState, useEffect } from 'react';
import './SocialProof.css'; // Import the CSS file

const testimonials = [
  {
    name: 'Rahul ',
    photo: 'https://media-bom1-2.cdn.whatsapp.net/v/t61.24694-24/368268422_334389678993264_5540584778337691790_n.jpg?ccb=11-4&oh=01_ASCnGtC5NLdfBn6vhJUElo4v35rTK0us_LFJUFf8bBO3Ig&oe=66181360&_nc_sid=e6ed6c&_nc_cat=110',
    rating: 4.2,
    text: "As a relatively new trader, I was hesitant to make big moves in the market. But ever since I started using this trading indicator, my confidence has soared. Its clear buy and sell signals, along with real-time analysis, have given me the assurance I need to execute trades confidently. I've already seen a noticeable improvement in my trading results. Thank you for this amazing tool"
  },
  {
    name: 'Trader 1',
    photo: 'https://media-bom1-2.cdn.whatsapp.net/v/t61.24694-24/413668523_1522465194981869_4850681731371600926_n.jpg?ccb=11-4&oh=01_ASAtr2QlBqKhwjc9RyXoejRVHBxkqzLwMwiEgyAUBmG3iw&oe=6617F01E&_nc_sid=e6ed6c&_nc_cat=105',
    rating: 4.1,
    text: "I've tried numerous trading indicators in the past, but none come close to the accuracy and consistency of this one. The signals it provides are spot-on, and I've been impressed by how well it adapts to different market conditions. Whether it's intraday trading or swing trading, this indicator has been my go-to choice. It's truly incredible how it has transformed my trading approach"
  },
  {
    name: 'Trader 1',
    photo: 'https://media-bom1-2.cdn.whatsapp.net/v/t61.24694-24/379942768_1273669086665510_92867659938936190_n.jpg?ccb=11-4&oh=01_ASCX7kDsUXsE-6eJyNmzzB4jUIFjNxDWZRI_g3Lh2GMKSg&oe=66180961&_nc_sid=e6ed6c&_nc_cat=103',
    rating: 4.6,
    text: "This trading indicator is an absolute game-changer! It has simplified my trading process and made it more profitable than ever before. The customizable settings allow me to fine-tune it to my liking, while the user-friendly interface makes it easy to navigate. With its real-time analysis and precise signals, it's become an indispensable tool in my trading arsenal. Trust me, you won't regret investing in it!"
  },
  {
    name: 'Trader 1',
    photo: 'https://media-bom1-2.cdn.whatsapp.net/v/t61.24694-24/425105438_408245681903265_88715675510187629_n.jpg?ccb=11-4&oh=01_ASBYmpLzAE5gIhbWUejiltpEsSKvWW-bvr2kgf0sOQHwAQ&oe=66181270&_nc_sid=e6ed6c&_nc_cat=104',
    rating: 4.4,
    text: "I can't speak highly enough about this trading indicator. It's hands down the best investment I've made for my trading career. The results speak for themselves – increased profits, reduced losses, and a newfound sense of confidence in my trades. The customer support team has also been incredibly helpful and responsive, addressing any queries or concerns promptly. If you're serious about trading success, look no further!"
  },
  // Add more testimonials here...
];

const SocialProof = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    }, 3000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <section className="social-proof">
      <h2>What Our Users Say</h2>
      <div className="testimonials">
        {testimonials.map((testimonial, index) => (
          <div className={`testimonial ${index === currentIndex ? 'active' : ''}`} key={index}>
            <img src={testimonial.photo} alt={testimonial.name} />
            <p>{testimonial.text}</p>
            <p>Rating: {testimonial.rating}/5 ⭐</p>
          </div>
        ))}
        <button className="prev" onClick={handlePrev}>&#10094;</button>
        <button className="next" onClick={handleNext}>&#10095;</button>
      </div>
    </section>
  );
};

export default SocialProof;
