// LimitedTimeOfferBanner.js

import React, { useState, useEffect } from 'react';
import "./LimitedTimeOffer.css"

const LimitedTimeOfferBanner = ({ expiryDate }) => {
    const [timeRemaining, setTimeRemaining] = useState('');

    // Calculate the time remaining
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const targetDate = new Date(expiryDate);
            const remainingMillis = targetDate - now;

            if (remainingMillis <= 0) {
                clearInterval(interval);
                setTimeRemaining('Offer Expired');
            } else {
                const seconds = Math.floor(remainingMillis / 1000) % 60;
                const minutes = Math.floor(remainingMillis / 1000 / 60) % 60;
                const hours = Math.floor(remainingMillis / 1000 / 60 / 60) % 24;
                const days = Math.floor(remainingMillis / 1000 / 60 / 60 / 24);

                setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
                // console.log(`${days}d ${hours}h ${minutes}m ${seconds}s`);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [expiryDate]);

    return (
        <div className="limited-time-offer-banner">
            <p className="offer-message">Limited Time Offer!</p>
            <p className="expiry-timer">{timeRemaining}</p>
        </div>
    );
};

export default LimitedTimeOfferBanner;
