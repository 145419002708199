import React from 'react';
import './Header.css'; // Import the CSS file

const Header = () => {
  return (
    <header className="header">
      {/* <img src="https://www.shubhyadav.tech/assets/img/profile-img.jpg" alt="Logo" className="logo" /> */}
      <p>"SUPERCHARGE YOUR TRADING WITH 'AI-DRIVEN STRATEGIES' WITHOUT THE COMPLEXITY"</p>
    </header>
  );
};

export default Header;
