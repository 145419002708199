import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./FormComponent.css"

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    // tv_username: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formName = "trading-indicator"
      const response = await fetch(`https://indicatoraccess.onrender.com/v1/collect/${formName}`, {
        method: "POST",
        body: JSON.stringify({ data: formData }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        console.log("Form submitted successfully");
        toast.success("Form submitted successfully");
        console.log(response);
        // Optionally, redirect users to another page
        window.location.href = "https://chat.whatsapp.com/CGAJVzqvjFC7UezJOO2ff8"; // Replace with your desired URL
      } else {
        console.error("Form submission failed:", response.status);
        toast.error(`Form submission completed: ${response.status}`);
        window.location.href = "https://chat.whatsapp.com/CGAJVzqvjFC7UezJOO2ff8"; // Replace with your desired URL
      }
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Form submission Completed:", error.message);
      window.location.href = "https://chat.whatsapp.com/CGAJVzqvjFC7UezJOO2ff8"; // Replace with your desired URL
    }
  };

  return (
    <div className='form-wrapper'>
      <form id='Form' onSubmit={handleSubmit}>
        <div className="field-wrapper">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="field-wrapper">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="field-wrapper">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        {/* <div className="field-wrapper">
          <label htmlFor="tv_username">Trading View Username :</label>
          <input
            placeholder="Leave Blank if you don't have one"
            type="text"
            id="tv_username"
            name="tv_username"
            value={formData.tv_username}
            onChange={handleChange}
          />
        </div> */}

        <button type="submit">Yes I Am Ready To Make Profit</button>
      </form>
    </div>
  );
};

export default FormComponent;
